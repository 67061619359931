import "./App.scss";

import React, { useEffect } from "react";

import Footer from "./theme/components/footer/footer";
import Home from "./pages/home/home";

function App() {

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 300) {
        // if scroll down hide the navbar
      } else {
        // if scroll up show the navbar
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, []);

  return (
    <div className="app w-screen overflow-x-hidden">
      <section className="content">
        <Home />
      </section>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
