import "./home.scss";

import React, { FC, useEffect, useRef, useState } from "react";

import Artist from "../../assets/images/static/avatars/art.png";
import CEO from "../../assets/images/static/avatars/ceo.png";
import CMO from "../../assets/images/static/avatars/cmo.png";
import COO from "../../assets/images/static/avatars/coo.png";
import Developer from "../../assets/images/static/avatars/dev.png";
import Writer from "../../assets/images/static/avatars/writer.png";
import ArtDesigner from "../../assets/images/static/avatars/art-designer.png";
import DirectorGlobalOperations from "../../assets/images/static/avatars/dogo.png";

import Artist_2 from "../../assets/images/static/avatars/art.png";
import CEO_2 from "../../assets/images/static/avatars/ceo-hover.png";
import CMO_2 from "../../assets/images/static/avatars/cmo-hover.png";
import COO_2 from "../../assets/images/static/avatars/coo-hover.png";
import Developer_2 from "../../assets/images/static/avatars/dev-hover.png";
import Writer_2 from "../../assets/images/static/avatars/writer-hover.png";
import ArtDesigner_2 from "../../assets/images/static/avatars/art-designer-hover.png";
import DirectorGlobalOperations_2 from "../../assets/images/static/avatars/dogo-hover.png";

import Disciples from "../../components/disciples/disciples";
import Hero from "../../components/hero/hero";
import { ImPause2 } from "react-icons/im";
import Journal from "../../components/journal/journal";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";
import Map from "../../components/map/map";
import Play from "../../assets/icons/play-button.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightArrow.svg";
import { Waypoint } from "react-waypoint";

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const video = useRef(null);

  const journal = useRef();
  const [page, setPage] = useState(0);
  const [playing, play] = useState(true);
  const [isHoverVideoPlayer, setIsHoverVideoPlayer] = useState(false);
  const [isShowSection, setIsShowSection] = useState(false);

  const disciples = [
    {
      image: CEO,
      image_2: CEO_2,
      name: "Daniel Draco",
      desig: "CEO/CTO/CO-FOUNDER",
    },
    {
      image: CMO,
      image_2: CMO_2,
      name: "Crypto with Amber",
      desig: "CMO/Global Sales Director/CO-FOUNDER",
    },
    {
      image: COO,
      image_2: COO_2,
      name: "John Draco",
      desig: "COO/CO-FOUNDER",
    },
    {
      image: DirectorGlobalOperations,
      image_2: DirectorGlobalOperations_2,
      name: "Riri",
      desig: "Director of Global Operations",
    },
    {
      image: Developer,
      image_2: Developer_2,
      name: "Harsha",
      desig: "Developer",
    },
    {
      image: Artist,
      image_2: Artist_2,
      name: "ThreeEyessOneMind",
      desig: "Artist",
    },
    {
      image: ArtDesigner,
      image_2: ArtDesigner_2,
      name: "Hayro",
      desig: "Art Design/Moderator/Tastemaker",
    },
    {
      image: Writer,
      image_2: Writer_2,
      name: "Chase",
      desig: "Writer/Journalist/Blogger",
    },
    {},
  ];

  const previous = () => {
    // @ts-ignore
    journal.current.previous();
  };

  const next = () => {
    // @ts-ignore
    journal.current.next();
  };

  const playVideo = () => {
    // @ts-ignore
    video.current.play();
  };

  const pauseVideo = () => {
    //@ts-ignore
    video.current.pause();
  };
  const handleWaypointEnter = () => {
    //
  };

  const handleWaypointLeave = () => {
    //@ts-ignore
    video.current.requestPictureInPicture();
  };

  const handleScroll = (event: any) => {
    const scrollTop = event.srcElement.scrollTop;
    setIsShowSection(document.documentElement.clientHeight * 0.5 < scrollTop);
  };

  useEffect(() => {
    document
      .querySelector(".app")
      ?.addEventListener("scroll", handleScroll, { passive: true });

    return () =>
      document
        .querySelector(".app")
        ?.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="home">
      {/* Hero Section */}
      <Hero />

      <div
        id="second_block"
        className="flex w-full showbgcontainer"
        style={{ height: "104vh" }}
      >
        <div
          className={`w-1/2 h-full bg-transparent sec sec-p1 ${
            isShowSection ? "showbg" : ""
          }`}
        ></div>
        <div
          className={`w-1/2 h-full bg-transparent sec sec-p2 ${
            isShowSection ? "showbg" : ""
          }`}
        ></div>
      </div>

      {/* Description block */}
      <div className="desc-block bg-blend-darken md:pb-40">
        <div className="masked-container">
          <div className="image"></div>
        </div>

        <div className="bg-transparent my-4 max-w-[90%] md:max-w-[60%] z-1">
          <h3 className="text-white bg-transparent text-center font-[Anton] py-4 text-3xl md:text-7xl 2xl:text-8xl md:mt-12">
            WE ARE HERE TO BUILD
          </h3>
          <p className=" text-white py-4 font-[WorkSans] tracking-wide text-sm md:text-base 2xl:text-xl text-justify">
            We have been developing software and engineering products for
            decades. We are here to build web3 solutions we believe the space
            will benefit from.
          </p>
          <p className=" text-white py-4 tracking-wide text-sm md:text-base 2xl:text-xl text-justify">
            As a 51DRACO holder you will benefit from all products we bring to
            market. We will be doing Air Drops quite often to reward and
            incentivize holders. The goal is to bring enormous value to the
            holders regardless if you are here to flip or diamond hand, we only
            care about moving the value of our collection up and to the right.
          </p>
          <p className=" text-white py-4 tracking-wide text-sm md:text-base 2xl:text-xl text-justify">
            Collaboration is key and we respect the blue chip NFT projects that
            have come before us. We are only here to contribute and add value to
            the NFT / Web3 ecosystem.
          </p>
          <p className=" text-white py-4 tracking-wide text-sm md:text-base 2xl:text-xl text-justify">
            We are excited for you all to join us on the journey, let's build
            amazing products together.
          </p>
        </div>

        {/* <div className='relative w-full'>
          <div className="absolute left-0 -bottom-28 lg:-bottom-36">
            <img src={GodFather} width="3000" height="3000" className="w-[90%] md:w-[80%] lg:w-[950px] z-10 block"/>
          </div>

          <div className='slider'>
            <div className='bg-repeat-x w-full h-[133px] mb-4 md:mt-[150px] lg:mt-[250px]'></div>
            <div className='bg-repeat-x w-full h-[133px] mb-4'></div>
            <div className='bg-repeat-x w-full h-[133px] mb-4'></div>
          </div>
        </div> */}
      </div>

      {/* First Content Block */}
      <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
        <div className="greeting-block py-20" id="about">
          <div className="video-container">
            <video
              ref={video}
              className="video-player"
              preload="auto"
              muted={false}
              autoPlay={true}
              playsInline={true}
              onPlay={() => {
                play(true);
                // @ts-ignore
                // console.log(video.current.requestPictureInPicture())
              }}
              onPause={() => play(false)}
              onLoadedData={() => {
                play(false);
              }}
              onMouseEnter={() => {
                setIsHoverVideoPlayer(false);
              }}
              onMouseLeave={() => {
                setIsHoverVideoPlayer(true);
              }}
            >
              <source
                src="https://res.cloudinary.com/ddbyhj769/video/upload/v1671075839/51draco/prfxvf1ezoujl3lxehi7.mp4"
                type="video/mp4"
              />
            </video>
          </div>

          {!playing ? (
            <img
              className="play-button"
              src={Play}
              alt="play button"
              onClick={playVideo}
            />
          ) : (
            <ImPause2
              className={`pause-button ${
                isHoverVideoPlayer ? "opacity-80" : "opacity-0"
              }`}
              onClick={pauseVideo}
            />
          )}
        </div>
      </Waypoint>

      {/* Island Map */}
      <div className="island-map-block md:pb-44 pb-52 p-5" id="Island">
        {/* <div className="masked-container">
          <div className="image"></div>
          <div className="overlay"></div>
        </div> */}

        <div className="content-block">
          <span className="header">INTERSTELLAR TRAVEL GUIDE</span>

          <div className="lg:mx-20 md:mx-10 bg-transparent md:mt-20 mt-10 md:rounded-box map">
            <Map />
          </div>
        </div>
      </div>

      {/* server block */}
      <div className="server-block">
        <div className="masked-container">
          <div className="image"></div>
        </div>

        <div className="flex w-full min-h-screen items-center justify-center text-center">
          {/* <span className="header lg">THE story so far
            <span className="subtitle">WTF is actually going on?!</span>
          </span> */}
        </div>
      </div>

      {/* Disciples */}
      <div className="disciples-block pb-20 md:py-20" id="Disciples">
        <div className="masked-container">
          <div className="image"></div>
          <div className="overlay"></div>
        </div>

        <div className="content-block">
          <span className="header md:pb-40 py-16">WE ARE 51DRACO</span>

          <div className="flex flex-row gap-4 flex-wrap justify-center">
            {disciples.map((x, i) => (
              <div
                key={i}
                className={`md:w-1/4 mx-6  ${i === 8 ? "h-0" : "mb-10"}`}
              >
                <Disciples data={x} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Parallax */}
      <div className="parallax">
        <div className="bg-center w-full bg-cover bg-no-repeat md:bg-fixed min-h-screen"></div>

        {/*<div className="faqs md:py-20" id="FAQS">*/}
        {/*  <div className="masked-container">*/}
        {/*    <div className="image"></div>*/}
        {/*    <div className="overlay"></div>*/}
        {/*  </div>*/}

        {/*  <span className="header">51 FAQS</span>*/}

        {/*  <div className="m-10 max-w-[90%] md:max-w-[60%]">*/}
        {/*    <ul className="max-h-auto w-[100%] md:w-[60%] mx-auto">*/}
        {/*      <li>*/}
        {/*        <h3 className="faq text-justify">*/}
        {/*          What blockchain are you minting on?*/}
        {/*        </h3>*/}
        {/*        <p className="answer text-justify">*/}
        {/*          Ethereum - We are huge SOL fans but as a result of the*/}
        {/*          potential fud that comes along with dealing with bad actors we*/}
        {/*          decided the better route would be ETH. However, it’s important*/}
        {/*          to know that we absolutely love the SOL community and planned*/}
        {/*          to be a SOL project. The issue is when you put an ungodly*/}
        {/*          amount of time into creating a collection and building*/}
        {/*          technology you do not want to be blind-sided by the*/}
        {/*          unexpected. Potentially, we can build the best collection ever*/}
        {/*          on SOL and if the wrong news comes out and it goes to zero we*/}
        {/*          are also worth zero. Zero of zero is zero and we didn’t feel*/}
        {/*          comfortable exposing our devs, artists, and otherwise to that*/}
        {/*          risk. That all being said, personally we love SOL and want the*/}
        {/*          best for its future.*/}
        {/*        </p>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <h3 className="faq text-justify">*/}
        {/*          Why is the project called 51 Draco?*/}
        {/*        </h3>*/}
        {/*        <p className="answer text-justify">*/}
        {/*          51 Draco is a star in the Draconis constellation. It’s where*/}
        {/*          we are from.*/}
        {/*        </p>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <h3 className="faq text-justify">*/}
        {/*          I see that you’ll be doing revenue share airdrops will you*/}
        {/*          also be doing art airdrops?*/}
        {/*        </h3>*/}
        {/*        <p className="answer text-justify">*/}
        {/*          Yes, we plan on doing quarterly rev share airdrops and other*/}
        {/*          interesting airdrops every other month. We want the project to*/}
        {/*          perpetually reward our holders.*/}
        {/*        </p>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <h3 className="faq text-justify">*/}
        {/*          Have you supported other NFT projects by minting/buying on*/}
        {/*          secondary, or otherwise?*/}
        {/*        </h3>*/}
        {/*        <p className="answer text-justify">*/}
        {/*          Yes, we actually feel like we own at least 1 piece of nearly*/}
        {/*          every collection.. good, bad, or indifferent. We are*/}
        {/*          definitely NFT degens to a certain extent.*/}
        {/*        </p>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <h3 className="faq text-justify">*/}
        {/*          How many are in your collection?*/}
        {/*        </h3>*/}
        {/*        <p className="answer text-justify">10,000</p>*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      {/* Journal BlocK */}
      <div className="journal-block py-20" id="Journal">
        <div className="masked-container">
          <div className="image"></div>
          <div className="overlay"></div>
        </div>

        <div className="content-block md:pb-36 pb-28">
          <div className="mb-4">
            <span className="header">ENGINEERING THE FUTURE</span>
          </div>

          <div className=" mb-4">
            <a
              className="cursor-pointer text-[#F4F1B9] font-[WorkSans]"
              href="/assets/blueprint/blueprint.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                className="inline ml-1"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z"></path>
              </svg>
            </a>
          </div>

          <div className="journal-container mx-auto md:shadow-2xl rotate-[2deg]">
            <Journal ref={journal} onFlip={setPage} />
          </div>

          <div className="flex flex-col w-full justify-center items-center">
            <div className="flex flex-row">
              <span className="py-0 mt-6 uppercase font-[Anton] text-[#F4F1B9] text-2xl">
                {page + 1}
              </span>
            </div>

            <div className="flex flex-row-reverse w-full justify-center items-center">
              <div
                className="text-[#F4F1B9] cursor-pointer mt-3 font-[WorkSans] mx-3"
                onClick={next}
                style={{ width: "77px" }}
              >
                <RightArrow />
              </div>
              <div
                className="text-[#F4F1B9] cursor-pointer mt-3 font-[WorkSans] mx-3"
                onClick={previous}
                style={{ width: "77px" }}
              >
                <LeftArrow />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="god-father-block h-[360px] md:h-[400px] lg:h-[560px] xl:h-[620px] 2xl:h-[820px]">
        <div className="masked-container h-[400px] md:h-[440px] lg:h-[600px] xl:h-[660px] 2xl:h-[900px]">
          <div className="image"></div>
        </div>

        <div className="empty-block"></div>
      </div>

      <div className="parallax">
        <div className="faqs md:py-20" id="FAQS">
          <div className="masked-container">
            <div className="image"></div>
            <div className="overlay"></div>
          </div>

          <span className="header">51 FAQS</span>

          <div className="m-10 max-w-[90%] md:max-w-[60%]">
            <ul className="max-h-auto w-[100%] md:w-[60%] mx-auto">
              {/*<li>*/}
              {/*  <h3 className="faq text-justify">*/}
              {/*    What blockchain are you minting on?*/}
              {/*  </h3>*/}
              {/*  <p className="answer text-justify">*/}
              {/*    Ethereum*/}
              {/*  </p>*/}
              {/*</li>*/}
              <li>
                <h3 className="faq text-justify">
                  Why is the project called 51 Draco?
                </h3>
                <p className="answer text-justify">
                  51 Draco is a star in the Draconis constellation. It’s where
                  we are from.
                </p>
              </li>
              <li>
                <h3 className="faq text-justify">
                  I see that you’ll be doing revenue share airdrops will you
                  also be doing art airdrops?
                </h3>
                <p className="answer text-justify">
                  Yes, we plan on doing quarterly rev share airdrops and other
                  interesting airdrops every other month. We want the project to
                  perpetually reward our holders.
                </p>
              </li>
              <li>
                <h3 className="faq text-justify">
                  Have you supported other NFT projects by minting/buying on
                  secondary, or otherwise?
                </h3>
                <p className="answer text-justify">
                  Yes, we actually feel like we own at least 1 piece of nearly
                  every collection.. good, bad, or indifferent. We are
                  definitely NFT degens to a certain extent.
                </p>
              </li>
              <li>
                <h3 className="faq text-justify">
                  How many are in your collection?
                </h3>
                <p className="answer text-justify">10,000</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Story block */}
      {/* <div className="story-block" id="Story">
        <div className="masked-container">
          <div className="image"></div>
          <div className="overlay"></div>
        </div>

        <div className="flex w-full min-h-screen items-center justify-center text-center">
          <span className="header lg">
            THE story so far
            <span className="subtitle">WTF is actually going on?!</span>
          </span>
        </div>
      </div> */}

      {/* <div className='content-block'>
          <div className="masked-container">
            <div className="image"></div>
          </div>
          <div className='content-block w-auto md:mx-20 md:px-20 md:my-20 mx-10 min-h-full'>
          </div>

          <div className='max-w-7xl mx-auto'>
            <div className='w-full rounded-lg my-10 md:mb-20 grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 gap-4'>
              <img src={Right} alt='Lore Left'/>
              <img src={Left} alt='Lore Right'/>
            </div>
          </div>
      </div> */}

      {/* Principles block */}
      {/* <div className='principles-block content-block pb-40'>
        <div className="masked-container">
          <div className="image"></div>
        </div>

        <div className='content-block'>
          <span className="header">
            Principles
            <span className="subtitle">
              UNDERSTAND WHAT DRIVES THE TRIBE FORWARD
            </span>
          </span>

          <div className='grid md:gap-10 md:grid-cols-3 md:grid-rows-1 grid-cols-1 bg-transparent'>
            <div className="md:w-[80%] principle md:my-0 my-4">
              <span className="font-[WorkSans] text-white font-bold text-4xl uppercase">I</span>

              <h4 className="text-white py-4 font-[Chillow] 2xl:text-5xl text-3xl">Community</h4>

              <p className="p-8 text-left text-white text-sm 2xl:text-xl leading-7 tracking-normal 
              font-[WorkSans]">
                It may not be a cult, but we did set out to build the most tight-knit, 
                empowered community on Solana. Everything we do — from our branding choices 
                to our utilities to the way we reveal details about the project — has been 
                carefully engineered to build hype, drive engagement, and create opportunities 
                for the Tribe to connect deeply. We believe in strength in numbers. 
                Apes ascend as one.
              </p>
            </div>

            <div className="md:w-[80%] principle md:my-0 my-4">
              <span className="font-[WorkSans] text-white font-bold text-4xl uppercase">II</span>
              
              <h4 className="text-white py-4 font-[Chillow] 2xl:text-5xl text-3xl">Identity</h4>
              <p className="p-8 text-left text-white text-sm 2xl:text-xl leading-7 tracking-normal 
              font-[WorkSans]">
                An important part of building a powerful cult…ure is knowing what we are and what 
                we aren't. Trippin' Ape Tribe is a community built by and for free-thinking 
                degens. By embracing our true nature, we welcome in genuine creative inspiration 
                and new strategic partnerships with like-minded brands and collaborators that 
                can all spread the mission further.
              </p>
            </div>

            <div className="md:w-[80%] principle md:my-0 my-4">
              <span className="font-[WorkSans] text-white font-bold text-4xl uppercase">III</span>
              <h4 className="text-white py-4 font-[Chillow] text-3xl 2xl:text-5xl">Innovation</h4>
              <p className="p-8 text-left text-white text-sm 2xl:text-xl leading-7 tracking-normal 
              font-[WorkSans]">
                Thanks to Chorles and his infinite wisdom, our consciousness continues to expand, 
                illuminating possibilities where once we only saw limits. We will continue to 
                bring big-brain thinking and next-level utilities to the project to keep our 
                Tribe guessing and the Solana ecosystem moving forward. Trust the process.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
