import "./disciples.scss";

import React, { FC, useState } from "react";

interface DisciplesProps {
  data: any;
}

const Disciples: FC<DisciplesProps> = (props: DisciplesProps) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };
  return (
    <div
      className="disciples md:py-10"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="relative">
        {props.data?.image && (
          <img
            src={props.data?.image}
            alt="Lysergic"
            className={`rounded-md shadow-md absolute ${
              hover ? "opacity-0" : "opacity-100"
            }`}
          />
        )}

        {props.data?.image_2 && (
          <img
            src={props.data?.image_2}
            alt="Lysergic"
            className={`rounded-md shadow-md`}
          />
        )}
      </div>

      <span className="name">
        {props.data.name}

        <span className="designation">{props.data.desig}</span>
      </span>
    </div>
  );
};

export default Disciples;
