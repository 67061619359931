import "./hero.scss";

import React, { FC, useEffect, useState } from "react";

import { IoArrowDownCircleOutline } from "react-icons/io5";

const Hero: FC = () => {
  const [mobileMode, setMobileMode] = useState(true);
  const handleResize = () => {
    if (window.innerWidth > 640) {
      setMobileMode(false);
    } else {
      setMobileMode(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return (
    <div className="hero-block relative xl:h-screen">
      <video
        loop={true}
        autoPlay={true}
        muted={true}
        playsInline={true}
        className={mobileMode ? "block" : "hidden"}
      >
        <source
          src="https://res.cloudinary.com/dc6fkiatd/video/upload/c_crop,h_1080,w_907/v1671664726/51draco/videos/draco-1080-high-faster-mobile_mgjasa.mp4"
          type="video/mp4"
        />
      </video>

      <video
        loop={true}
        autoPlay={true}
        muted={true}
        playsInline={true}
        className={mobileMode ? "hidden" : "block"}
      >
        <source
          src="https://res.cloudinary.com/ddbyhj769/video/upload/q_auto/v1670821038/51draco/draco-1080-high-faster-2.mp4"
          type="video/mp4"
        />
      </video>

      <div className="text-block absolute top-1/2 md:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
        <h1 className="main-text header">51DRACO</h1>
        <p className="text-white py-5 md:block font-[WorkSans] text-center tracking-wide text-xl md:text-base 2xl:text-xl">
          Shall we takeover Web3 together?
        </p>

        <a className="discord-button bg-violet-700 hover:bg-violet-900 rounded inline-flex items-center mt-4 md:mt-8" 
        href="https://discord.gg/VSSNG4Cv85"  target="_blank" rel="noreferrer">
          <i className="icon"></i>

          Join us on Discord
        </a>

        <a href="#second_block">
          <IoArrowDownCircleOutline className="animate-bounce text-white text-3xl md:text-6xl mt-16 md:mt-20" />
        </a>
      </div>
    </div>
  );
};

export default Hero;
