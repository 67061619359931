import "./footer.scss";

import React, { FC } from "react";

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
  <div className="footer">
    <div className="bg-transparent w-full max-h-auto py-6 flex-row flex items-center justify-evenly">
      <div className="flex flex-col md:flex-row items-center justify-center">
        <span className="text-white bg-transparent font-[Anton] md:text-base text-xs py-1 md:py-0">
          2022, 51Draco
        </span>
        {/*<a*/}
        {/*  className="text-white bg-transparent text-xs font-[Anton] mx-5"*/}
        {/*  href="#"*/}
        {/*  target="_blank"*/}
        {/*  rel="noreferrer"*/}
        {/*>*/}
        {/*  Terms &amp; Conditions*/}
        {/*</a>*/}
      </div>
    </div>
  </div>
);

export default Footer;
