import React, { FC } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import Glow from "../../assets/images/components/map/star-highlighted.png";

interface MapPointProps {
  description: string;
  header: string;
  setSelectedMapPoint: (description: string | null) => void;
  setMapDescription: (description: string | null) => void;
  selectedMapPoint: string | null | undefined;
  labelClasses: string;
  mapPointId: string;
  canClearMapDescription: boolean;
}

const MapPoint: FC<MapPointProps> = ({
  selectedMapPoint,
  setSelectedMapPoint,
  setMapDescription,
  header,
  description,
  labelClasses,
  mapPointId,
  canClearMapDescription,
}) => {
  const handleMapPointClick = () => {
    setSelectedMapPoint(mapPointId);
    setMapDescription(description);
  };

  const clearMapDescription = () => {
    if (selectedMapPoint === mapPointId && canClearMapDescription) {
      setSelectedMapPoint(null);
      setMapDescription(null);
    }
  };

  const mapPointRef = useDetectClickOutside({
    onTriggered: clearMapDescription,
  });

  return (
    <>
      <a
        href={`#${mapPointId}`}
        onClick={handleMapPointClick}
        ref={mapPointRef}
      >
        <div className={mapPointId}>
          <img
            src={Glow}
            alt={mapPointId}
            className={`hover-show ${
              selectedMapPoint === mapPointId ? "glow" : null
            } `}
          />

          <label className={`label w-[18vw] ${labelClasses}`}>
            <p
              className={`text-white text-[0.45rem] sm:text-sm md:text-lg lg:text-[1.4rem] xl:text-[2rem] lg:leading-9 xl:leading-10 font-Orbitron tracking-wide text-shadow-blackBorder hover:text-shadow-yellowGlow ${
                selectedMapPoint === mapPointId ? "text-shadow-yellowGlow" : ""
              }`}
            >
              {header}
            </p>
          </label>
        </div>
      </a>
    </>
  );
};

export default MapPoint;
