import './journal.scss';

import React, { FC, useImperativeHandle, useRef, useState, useEffect } from 'react';

import HTMLFlipBook from 'react-pageflip';
import Page1 from "../../assets/images/components/blueprint/page-1.png";
import Page2 from "../../assets/images/components/blueprint/page-2.png";
import Page3 from "../../assets/images/components/blueprint/page-3.png";

interface JournalProps {
  onFlip?: any,
  ref?: any
}

const Journal: FC<JournalProps> = React.forwardRef((props: JournalProps, ref: any) => {
  const book = useRef();
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    previous() {
      if (book.current) {
        // @ts-ignore
        book.current.pageFlip().flipPrev();
      }
    },
    next() {
      if (book.current) {
        // @ts-ignore
        book.current.pageFlip().flipNext();
      }
    }
  }));

  

  const onPageUpdate = (ev: any) => {
    props.onFlip(ev.data);
  }

  const calculateHeightAndWidth = () => {
    const _width = window.innerWidth - ((window.innerWidth / 100) * 25);

    setWidth(_width);
    setHeight((_width / 16) * 9);
  };

  useEffect(() => {
    window.addEventListener("resize", calculateHeightAndWidth);
  });

  useEffect(() => {
    calculateHeightAndWidth();
  }, []);

  const journal = () => {
    // @ts-ignore
    return (<HTMLFlipBook 
      ref={book}
      width={width}
      height={height}
      usePortrait={true}
      size={'fixed'}
      onFlip={onPageUpdate}>
      <img key={1} src={Page1} alt='Page 1'/>
      <img key={2} src={Page2} alt='Page 2'/>
      <img key={3} src={Page3} alt='Page 3'/>
    
    </HTMLFlipBook>)
  }

  return (
    <div className="journal">
      {journal()}
    </div>
  );
});

export default Journal;
