import "./map.scss";
import "simplebar-react/dist/simplebar.min.css";

import React, { FC, useRef, useState } from "react";

import { AiOutlineCloseSquare } from "react-icons/ai";
import MapPoint from "./map_point";
import SimpleBar from "simplebar-react";
import stars from "../../assets/images/components/map/star-map.jpg";

interface MapProps {}

const Map: FC<MapProps> = () => {
  const [selectedMapPoint, setSelectedMapPoint] = useState<string | null>();
  const [mapDescription, setMapDescription] = useState<string | null>();
  const [showCloseButton] = useState<boolean>(true);
  const [canClearMapDescription, setCanClearMapDescription] =
    useState<boolean>(false);

  const mapDescRef = useRef<null | HTMLDivElement>(null);
  const mapDescMobileRef = useRef<null | HTMLDivElement>(null);

  const map_descriptions = [
    {
      id: "l1",
      header: "Merch Store Avail (only for holders)",
      description:
        "51 Draco founders have a decade plus long running relationship with an apparel company here in Los Angeles. This company was responsible for the first runs of the BAYC merchandise packs. We will be utilizing them for our merchandise. This apparel will be exclusive to 51 Draco holders.",
      labelClasses: "w-[18vw] lg:w-[20vw] top-[-70%] left-[120%] xl:top-[-10%]",
    },
    {
      id: "l3",
      header: "Launch Draco PayGO",
      description:
        "The payroll of the future is here. <br/> <br/> As the market for Web3 products continues to grow around the world, more and more businesses are paying their workforce with cryptocurrencies. While that’s perfect for the payee, scheduling payments and continually converting USD salaries to cryptocurrency can be a nightmare for the employer." +
        "<br/> <br/>" +
        "Draco PayGo is the solution. Web3’s answer to Gusto and ADT, Draco PayGo is blockchain-based employment payment system, designed to streamline the payroll process for businesses of all sizes. " +
        "<br/> <br/>" +
        "In a matter minutes, you can create your own payroll plan by connecting your company and employee cryptocurrency wallets, setting the frequency of payouts, and deciding how much money (in USD) to send per paycheck. Then, simply choose the preferred cryptocurrency for payment, and Draco PayGo will do the rest.  " +
        "<br/> <br/>" +
        "When triggered at the frequency date, our system calculates the correct amount of cryptocurrency to send and deposits each payment in the appropriate wallet. All payments are tracked and organized in user-friendly ledgers for effortless accounting.  " +
        "<br/> <br/>" +
        "Your information is stored privately, cryptographically secured, and only accessible to you.  " +
        "<br/> <br/>" +
        "Here’s how it works: " +
        "<br/> <br/>" +
        "Step 1: Connect your company wallet that will be distributing payments. " +
        "<br/> <br/>" +
        "Step 2: Enter the wallet addresses of your employees (or contractors) and assign them nicknames. " +
        "<br/> <br/>" +
        "Step 3: Enter the amount(s) they will be paid in USD. " +
        "<br/> <br/>" +
        "Step 4: Choose their preferred cryptocurrency to receive payment. " +
        "<br/> <br/>" +
        "Step 5: Select the frequency of payment (weekly, bi-monthly, monthly, quarterly, etc.). " +
        "<br/> <br/>" +
        "That’s it. When a trigger date occurs, Draco PayGo calculates how much cryptocurrency is owed based on the USD amount set for each wallet (and the current exchange rate) and delivers payment automatically. ",
      labelClasses:
        "w-[18vw] lg:w-[20vw] top-[-170%] left-[-150%] md:top-[-120%] md:left-[60%] xl:left-[-20%] xl:top-[-80%]",
    },
    {
      id: "l4",
      header: "Finalize DracoSwap Development",
      description:
        "DracoSwap allows you to connect to one interface and shop any blockchain. No more connecting to different wallets or marketplaces to purchase different crypto asset classes. One place for all your buying, portfolio tracking, P&L reports, plus more.",
      labelClasses:
        "w-[18vw] lg:w-[20vw] left-[100%] top-[-150%] xl:left-[60%] xl:top-[-70%]",
    },
    {
      id: "r1",
      header: "Mint",
      description: "We are excited to have you on our team!",
      labelClasses:
        "w-[18vw] lg:w-[20vw] top-[5%] left-[-20%] lg:left-[-100%] lg:top-[10%]",
    },
    {
      id: "r2",
      header: "Royalty Rev Share Staking",
      description:
        "he 51 Draco staking mechanism is used to properly credit 51 Draco holders with their pro-rata share of revenue dispensed from the royalty fund. To explain in the most basic terms, the more Draco’s you hold, the more % you receive of the disbursable amount every quarter. When staked you will also receive other airdrops and benefits.",
      labelClasses: "w-[18vw] lg:w-[20vw] top-[110%] left-[-160%]",
    },
    {
      id: "r3",
      header: "Quarterly Royalty Air Drop Starts",
      description:
        "TBD",
      labelClasses: "w-[18vw] lg:w-[20vw] top-[110%] left-[-200%]",
    },
  ];

  //@ts-ignore
  const checkCanClearMapDescription = (event) => {
    if (window.innerWidth > 640) {
      if (
        mapDescRef &&
        mapDescRef.current &&
        //@ts-ignore
        !mapDescRef.current.contains(event.target)
      ) {
        setCanClearMapDescription(true);
      } else {
        setCanClearMapDescription(false);
      }
    } else {
      if (mapDescMobileRef && mapDescMobileRef.current) {
        // doesn't work on mobile
        // if (!mapDescMobileRef.current.contains(event.target)) {
        //   setCanClearMapDescription(false);
        // } else {
        //   setCanClearMapDescription(true);
        // }
      }
    }
  };

  return (
    <div className="map" onClick={checkCanClearMapDescription}>
      <img src={stars} alt="Map" />

      {/* Map Description Desktop */}
      <div
        className={`${
          mapDescription ? "opacity-100" : "opacity-0"
        } transition-opacity duration-200 map-desc text-white font-press_start uppercase px-2 md:px-4 lg:px-6 xl:px-10 pt-5 md:pt-6 lg:pt-10 xl:pt-16 
        text-left text-[0.2rem] sm:text-[0.5rem] md:text-[0.6rem] lg:text-sm xl:text-base hidden sm:block`}
        ref={mapDescRef}
      >
        <SimpleBar style={{ height: "80%" }}>
          <p
            className="leading-3 md:leading-4 lg:leading-5 xl:leading-7 pr-2"
            dangerouslySetInnerHTML={{
              __html: mapDescription ? mapDescription : "",
            }}
          ></p>
        </SimpleBar>
      </div>

      {/* Map description Mobile */}
      <div
        className={`${
          mapDescription ? "show" : ""
        } map-desc-modal sm:hidden text-[0.6rem] px-5 pb-5 pt-10 leading-6 relative z-20`}
        // onMouseEnter={() => setShowCloseButton(true)}
        // onMouseLeave={() => setShowCloseButton(false)}
        ref={mapDescMobileRef}
      >
        {showCloseButton && (
          <AiOutlineCloseSquare
            className="absolute text-white right-8 top-8 cursor-pointer bg-black"
            size="2.5em"
            onClick={() => {
              setMapDescription(null);
              setSelectedMapPoint(null);
            }}
          />
        )}

        <p
          className="font-press_start uppercase border-2 white p-5 h-full text-left overflow-auto"
          dangerouslySetInnerHTML={{
            __html: mapDescription ? mapDescription : "",
          }}
        ></p>
      </div>

      {map_descriptions.map((map_desc, i) => (
        <MapPoint
          key={i}
          header={map_desc.header}
          description={map_desc.description}
          selectedMapPoint={selectedMapPoint}
          setSelectedMapPoint={setSelectedMapPoint}
          setMapDescription={setMapDescription}
          labelClasses={map_desc.labelClasses}
          mapPointId={map_desc.id}
          canClearMapDescription={canClearMapDescription}
        />
      ))}
    </div>
  );
};

export default Map;
